<template>
  <div class="tabulation">
    <el-card>
      <!-- 搜索框 -->
      <div class="grabble">
        <div style="width:57%">
          <el-input class="hunt" v-model="input" placeholder="工会名称" size="large" />
          <el-button style="margin-left: 10px;" type="primary" size="large"><el-icon class="cancelgu"> <Search /> </el-icon>搜索</el-button>
        </div>
        <div>
          <el-button style="margin-left: 10px" type="primary" size="large" @click="dialogFormVisible = true"
            ><el-icon class="cancelgu"> <Plus /> </el-icon>添加</el-button
          >
        </div>
      </div>
      <el-table
        :data="filterTableData"
        style="width: 100%"
        row-key="unionId"
        :tree-props="{ children: 'unionDtoList' }"
        @cell-mouse-enter="obtain"
        border
        :header-cell-style="{background:'#F5F7FA'}"
      >
        <el-table-column label="工会名称" prop="unionName" />
        <el-table-column label="类型" prop="unionType" align="center" :formatter="formatSex">
        </el-table-column>
        <el-table-column
          label="创立时间"
          prop="createTime"
          align="center"
          :formatter="quantum"
        >
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="scope">
            <el-button
              size="default"
              @click="copyreader(scope.$index, scope.row)"
              class="bulur"
              ><el-icon class="cancel"> <EditPen /> </el-icon>编辑</el-button
            >
            <el-button size="default" @click="expurgate" class="red"
              ><el-icon class="cancel"> <Delete /> </el-icon>删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 选择加添类型 -->
     <el-dialog v-model="project" title="添加" width="20%" center>
      <div class="items">
        <el-button type="primary" @click="expenditure()">工会添加</el-button>
        <el-button type="primary" @click="tissue()">组织添加</el-button>
      </div>
    </el-dialog>
    <!-- 分支增加弹窗 -->
    <el-dialog
      v-model="dialogFormVisible"
      title="组 织 添 加"
      width="30%"
      center
    >
      <div class="labor">
        <div class="designation">选择组织 ：</div>
        <el-select
          :value="treeLabel"
          v-model="treeLabel"
          clearable
          placeholder="请选择"
          ref="selectTree1"
          popper-class="modality"
          filterable 
          :filter-method="filterMethod"
        >
          <el-option :value="treeLabel" :label="treeLabel" class="option">
            <el-tree
              :data="texture"
              @node-click="handleNodeClick"
              node-key="ids"
              :props="defaultProps"
            >
            </el-tree>
          </el-option>
        </el-select>
      </div>
      <div class="labor">
        <div class="designation">组织名称 ：</div>
        <div><el-input v-model="designation" placeholder="请输入组织名称" /></div>
      </div>
      <div class="labor">
        <div class="designation">组织类型 ：</div>
        <div>
            <el-select
              v-model="value"
              class="m-2"
              placeholder="请选择组织类型"
              size="default"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="item in option"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="receipt()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="organ"
      title="分支工会添加"
      width="30%"
      center
    >
      <div class="labor">
        <div class="designation">分支工会名称 ：</div>
        <div><el-input v-model="price" placeholder="请输入分支工会名称" /></div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="ensure()">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 删除按钮弹窗 -->
    <el-dialog v-model="remove" title="工会删除" width="30%" center>
      <div class="labor">
        <div class="sure">确定删除此工会吗？</div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="abrogate">取消</el-button>
          <el-button type="primary" @click="affirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑工会按钮 -->
    <el-dialog v-model="redact" title="工会编辑" width="30%" center>
      <div class="labors">
        <div class="labor">
          <div class="designation">编辑工会名称 ：</div>
          <div><el-input v-model="prices" placeholder="请输入工会名称" /></div>
        </div>
        <div class="labor">
          <div class="designation">是否开启权限 ：</div>
          <div>
            <el-select
              v-model="tacitly"
              class="m-2"
              placeholder="是否开启权限"
              size="default"
              :popper-append-to-body="false"
              filterable
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="redact = false">取消</el-button>
          <el-button type="primary" @click="precise">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { ref, computed, onMounted } from "vue";
// import { useRoute } from "vue-router";
import { Branch, Affiliated, Expurgate, Edit } from "../../utils/api";
import qs from "qs";
import { Delete, EditPen, Search,Plus} from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
export default {
  setup() {
    // 版本二添加
    let organ =ref(false)
    let expenditure=()=>{
      organ.value=true
      project.value=false
    }
    let tissue=()=>{
      dialogFormVisible.value=true
      project.value=false
    }
    let project=ref(false)
    let designation =ref('')
    let receipt=()=>{
      console.log(value.value)
      console.log(designation.value)
      console.log(noticeable.value)
      if(noticeable.value==null){
        ElMessage({
            message: "请选择组织",
            type: "warning",
            center: true,
          });
      }else if(value.value==''){
        ElMessage({
            message: "组织名称不能为空",
            type: "warning",
            center: true,
          });
      }else if(designation.value==''){
         ElMessage({
            message: "请选择组织类型",
            type: "warning",
            center: true,
          });
      }else{
        Affiliated({
          superiorUnionId: noticeable.value ,
          unionName: designation.value ,
          unionType: value.value,
        }).then((res) => {
          ElMessage({
            message: "添加成功！",
            type: "success",
            center: true,
          });
          console.log(res);
          dialogFormVisible.value = false;
          noticeable.value=null
          designation.value=''
          value.value=''
          receive();
        });
      }
    }
    // 版本二选择器
    let value = ref("");

    const option = [
      {
        value: "1",
        label: "工会",
      },
      {
        value: "2",
        label: "部门",
      },
    ];
    // 组织 工会
    const selectTree1= ref()
    let treeLabel =ref('')
    let tree =ref()
    let noticeable =ref(null)
    let handleNodeClick = (node) => {
      // node.id 是你所选中的ID
      // treeLabel展示在外界的中文名
      noticeable.value=node.unionId
      console.log(node)
      selectTree1.value.blur();
      treeLabel.value = node.unionName;
    };
    let filterMethod=(value)=>{
      console.log(value,tree)
    }
    let quantum = (row, column, cellValue) => {
      return cellValue.slice(0, 11);
    };
    let formatSex = (row, column, cellValue) => {
      if (cellValue == 1) {
        return "工会";
      } else if (cellValue === 2) {
        return "部门";
      }
    };
    let setting = ref("");
    let discover = (e) => {
      console.log(e);
      setting.value = e.unionName;
    };
    // 添加分支下拉列表
    const texture = ref([]);
    // 编辑工会
    let precise = () => {
      console.log(tacitly.value);
      Edit({
        isEnable: tacitly.value,
        parentId: parentId,
        unionId: uniId,
        unionType: uniType,
        unionName: prices.value,
      }).then((res) => {
        if (prices.value == "") {
          ElMessage({
            message: "工会名不能为空！",
            type: "warning",
            center: true,
          });
        } else if (res.data.code == 0) {
          ElMessage({
            message: "编辑成功！",
            type: "success",
            center: true,
          });
          receive();
          redact.value = false;
        }
      });
    };
    let tacitly = ref("");
    const options = [
      {
        value: "1",
        label: "开启",
      },
      {
        value: "2",
        label: "关闭",
      },
    ];
    let redact = ref(false);
    let prices = ref("");
    let copyreader = () => {
      redact.value = true;
    };
    // 删除工会
    let remove = ref(false);
    let affirm = () => {
      Expurgate({
        unionId: uniId,
      }).then((res) => {
        console.log(res.data.code);
        if (res.data.code == 0) {
          ElMessage({
            message: "删除成功！",
            type: "success",
            center: true,
          });
          remove.value = false;
          receive();
        }
      });
    };
    const abrogate = () => {
      remove.value = false;
    };
    let expurgate = () => {
      remove.value = true;
    };
    // 分支工会添加弹窗
    let matter = ref("");
    let uniId = ref(null);
    let uniType = ref(null);
    let isEnable = ref(null);
    let parentId = ref(null);
    let obtain = (row) => {
      uniId = row.unionId;
      uniType = row.unionType;
      prices.value = row.unionName;
      isEnable = row.isEnable;
      parentId = row.parentId;
      if (row.isEnable == 1) {
        tacitly.value = "1";
      } else if (row.isEnable == 2) {
        tacitly.value = "2";
      }
    };
    // 添加弹窗
    let cancel = () => {
      organ.value = false;
      dialogFormVisible.value = false
    };
    let price = ref("");
    let dialogFormVisible = ref(false);
    let affixion = () => {
      project.value=true
    };
    let ensure = () => {
      if (price.value == "") {
        ElMessage({
          message: "工会名不能为空！",
          type: "warning",
          center: true,
        });
        dialogFormVisible.value = false;
      } else {
        Affiliated({
          superiorUnionId: sessionStorage.getItem("userId"),
          unionName: price.value,
          unionType: sessionStorage.getItem("unionType"),
        }).then((res) => {
          ElMessage({
            message: "添加成功！",
            type: "success",
            center: true,
          });
          console.log(res);
          organ.value=false
          receive();
        });
      }
    };
    // 获取分支工会
    let currentPage = ref(1);
    const handleCurrentChange = (Page) => {
      currentPage.value = Page;
      console.log(Page);
      console.log(currentPage);
    };
    const handleSizeChange = (size) => {
      pagesize.value = size;
    };
    let pagesize = ref(8);
    let unionTypes = ref("");
    const receive = () => {
      const data = {
        unionId: sessionStorage.getItem("userId"),
      };
      Branch(qs.stringify(data)).then((res) => {
        tableData.value = res.data.data;
        texture.value = res.data.data;
        console.log(res);
      });
    };
    // 树形下拉添加
    const defaultProps = {
      children: "unionDtoList",
      label: "unionName",
    };
    onMounted(() => {
      receive();
      // console.log(accept);
    });
    const search = ref("");
    const filterTableData = computed(() =>
      tableData.value.filter(
        (data) =>
          !search.value ||
          data.unionName.toLowerCase().includes(search.value.toLowerCase())
      )
    );
    const tableData = ref([]);
    return {
      filterMethod,
      expenditure,
      organ,
      tissue,
      project,
      noticeable,
      designation,
      value,
      receipt,
      option,
      selectTree1,
      treeLabel,
      handleNodeClick,
      quantum,
      formatSex,
      setting,
      discover,
      defaultProps,
      texture,
      filterTableData,
      search,
      pagesize,
      handleCurrentChange,
      handleSizeChange,
      currentPage,
      dialogFormVisible,
      affixion,
      ensure,
      price,
      cancel,
      obtain,
      uniId,
      uniType,
      matter,
      remove,
      expurgate,
      abrogate,
      affirm,
      redact,
      copyreader,
      prices,
      options,
      tacitly,
      precise,
      isEnable,
      parentId,
      unionTypes,
    };
  },
  components: {
    Delete,
    EditPen,
    Search,
    Plus
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-table__body{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
::v-deep .el-table__header{
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.hunt{
  width: 36.5%;
}
.grabble {
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}
.el-table__header{
  margin-top: 0;
  margin-bottom: 0;
}
.red{
  color: red;
  border: none;
  --el-button-bg-color:none;
  --el-button-hover-bg-color:none;
  --el-button-hover-text-color:none;
}
.red:hover{
  color: #fab6b6;
}
.bulur{
  color: #409EFF;
  border: none;
  --el-button-bg-color:none;
  --el-button-hover-bg-color:none;
  --el-button-hover-text-color:none;
}
.bulur:hover{
  color: #a0cfff;
}
// .el-button{
//   border: none;
//   --el-button-bg-color:none;
//   --el-button-hover-bg-color:none;
//   --el-button-hover-text-color:none;
// }
.el-scrollbar__thumb{
  background-color: #ffffff;
}
.items{
  display: flex;
}
.el-select .el-input__inner{
  width: 183px;
}
::v-deep .el-dialog--center .el-dialog__body{
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-select-dropdown__empty {
  display: none !important  ;
}
.el-select-dropdown__item {
  background-color: #ffffff;
  height: 40px !important;
}
.el-select-dropdown__item {
  overflow: visible;
}
.labor {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tianjia {
  margin-left: 20px;
}
.cancel {
  margin-right: 5px;
  font-weight: 400;
}
.cancelgu {
  margin-right: 6px;
  font-weight: bold;
}
::v-deep .el-table__inner-wrapper::before {
  width: 0;
}
::v-deep .el-table .el-table__cell {
  padding-top: 15px;
  padding-bottom: 15px;
}
.el-card.is-always-shadow {
  width: 95%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px
}
::v-deep .el-card__body {
  padding: 0;
}
.el-pagination {
  margin-top: 10px;
}
::v-deep .el-input--small .el-input__inner {
  height: 30px;
  line-height: 30px;
}
.tabulation {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
</style>
<style>
.el-popper {
  z-index: 10000 !important;
}
.modality{
  height: 150px !important;
}
.el-scrollbar__thumb{
  display: none !important;
}
 .el-select-dropdown__item.selected{
  font-weight: 500 !important;
}
</style>
